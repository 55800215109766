<template>
	<div class="p-fluid p-formgrid ">
		<div class="p-grid" v-if="inf.lesiones">
            <div class="p-field p-col-4" v-if="edicion">
                <label>{{$t('Monto')}}: </label>
                <InputNumber id="horizontal" v-model="inf.lesmonto" showButtons buttonLayout="horizontal" 
                decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success" incrementButtonIcon="pi pi-plus" 
                decrementButtonIcon="pi pi-minus" :min="0" mode="currency" :currency="$store.state.currency" style="width:90%;"/>
            </div>
			<div class="p-field p-col-4" v-else>
                <strong>{{$t('Monto')}}: {{ inf.lesmonto }}</strong>
            </div>
            <div class="p-field p-col-8 p-grid" style="background:#E7E7D9;"  v-if="edicion">
                <h5 style="width:100%;">{{$t('Cargar Documentos')}}</h5>
                <div class="p-field p-col-9">
                    <label>{{$t('Descripción')}}</label>
                    <InputText v-model="descripcion" type="text"/>
                    <small v-show="!descripcion" class="p-error">{{$t('required')}}</small>
                </div>
                <div class="p-field p-col-3">
                    <FileUpload class="p-mb-2" :chooseLabel="$t('JPG,JPEG')" uploadLabel="Cargar" mode="basic" :name="img" :url="url.api+'upload.php'"  accept="image/jpeg,image/jpg" :maxFileSize="4000000" @upload="myUploader3(img)" :auto="true"/>
                    <FileUpload class="p-mb-2" :chooseLabel="$t('PDF')" uploadLabel="Cargar" mode="basic" :name="pdf2" :url="url.api+'upload.php'"  accept="application/pdf" :maxFileSize="4000000" @upload="myUploader4(pdf2)" :auto="true"/>
                    <small class="p-error">{{$t('tamaño máximo')+": 4MB"}}</small>
                </div>
            </div>
            <div class="p-field p-col-12 p-grid">
                <DataTable :value="$store.state.claim.lension" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                    :rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters" v-if="edicion">
                    <Column :header="$t('Descripción')">
                        <template #body="slotProps">
                            {{slotProps.data.descripcion}}
                        </template>
                    </Column>
                    <Column :header="$t('Ver')" headerStyle="width: 20%">
                        <template #body="slotProps">
                            <img style="width: 25px;" :src="url.upload+slotProps.data.codigo" v-if="slotProps.data.format=='jpg'"  @click="IMG(url.upload+slotProps.data.codigo)"/>
                            <img style="width: 25px;" src="images/pdf.jpg" v-if="slotProps.data.format=='pdf'" @click="VerPDF(slotProps.data.codigo)"/>
                        </template>
                    </Column>
                    <Column :header="$t('Eliminar')" headerStyle="width: 20%">
                        <template #body="slotProps">
                            <Button :disabled ="$store.state.loading" icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="DeleteDoc(slotProps.data)"/>
                        </template>
                    </Column>
                </DataTable>
				<DataTable :value="$store.state.claim.lension" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                    :rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters" v-else>
                    <Column :header="$t('Descripción')">
                        <template #body="slotProps">
                            {{slotProps.data.descripcion}}
                        </template>
                    </Column>
                    <Column :header="$t('Ver')" headerStyle="width: 20%">
                        <template #body="slotProps">
                            <img style="width: 25px;" :src="url.upload+slotProps.data.codigo" v-if="slotProps.data.format=='jpg'"  @click="IMG(url.upload+slotProps.data.codigo)"/>
                            <img style="width: 25px;" src="images/pdf.jpg" v-if="slotProps.data.format=='pdf'" @click="VerPDF(slotProps.data.codigo)"/>
                        </template>
                    </Column>
                </DataTable>
                <OverlayPanel ref="op1" appendTo="body" :showCloseIcon="true">
                    <img style="width: 350px;" :src="imagen"/>
                </OverlayPanel>
            </div>
		</div>
	</div>
</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Reclamos','Claim2');
	import { useI18nPlugin } from '@unify/vuex-i18n';
	import Token from "uuid-token-generator";
	import url from "@/service/_URL";

	export default {
        props: {
            datos: {
                type: Object,
                default: null,
            },
			edit: {
                type: Boolean,
                default: false,
            },
        },
        data() {
			return {
				i18n: null,
				edicion: this.edit,
                inf: this.datos,
                descripcion: null,
                selectedCustomers: null,
                filters: {},
                url: url,
                pdf: null,
				img: null,
				pdf2: null,
				lpdf: null,
				imegan: null,
				pdf5: null,
				pdf6: null,
			}
		},
		created() {
			this.i18n = useI18nPlugin();
            const tokgen = new Token(256);
			this.pdf = tokgen.generate();
			this.lpdf = tokgen.generate();
			this.img = tokgen.generate();
			this.pdf2 = tokgen.generate();
			this.pdf5 = tokgen.generate();
			this.pdf6 = tokgen.generate();
		},
        methods: {
            IMG(imegan){
				this.imagen = imegan;
				this.toggle1(event);
			},
			toggle1(event) {
				this.$refs.op1.toggle(event);
			},
            VerPDF(document){
				window.open(url.upload+document+'.pdf', '_blank');
			},
            DeleteDoc(selec) {
				this.$store.state.claim.lension = this.$store.state.claim.lension.filter(val => val !== selec);
			},
			myUploader3(img) {
				if (this.descripcion == null || this.descripcion == '') {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: this.$t('Error en Descripción'), life: 10000});
				} else {
					Consulta.Procesar('RevFoto',{
						foto: img,
						descripcion: this.descripcion,
						claim: this.inf.id,
					}).then(response => {
                        //this.$store.state.error = response;
						if(response.result){					
							this.$store.state.claim.lension.push({
								id: 0, 
								claim: this.inf.id,
								descripcion:  this.descripcion, 
								format: 'jpg', 
								codigo: response.result
							});
							this.descripcion = null;
							const tokgen = new Token(256);
							this.img = tokgen.generate();
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: this.$t('Advertencia'), 
								detail: this.$t('Error en Foto'), 
								life: 3000
							});
						}
					});
				}
			},
			myUploader4(pdf) {
				if (this.descripcion == null || this.descripcion == '') {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: this.$t('Error en Descripción'), life: 10000});
				} else {
					Consulta.Procesar('RevPDF',{
						foto: pdf,
						descripcion: this.descripcion,
						claim: this.inf.id,
					}).then(response => {
                        //this.$store.state.error = response;
						if(response.result){					
							this.$store.state.claim.lension.push({
								id: 0, 
								claim: this.inf.id,
								descripcion:  this.descripcion, 
								format: 'pdf', 
								codigo: pdf
							});
							this.descripcion = null;
							const tokgen = new Token(256);
							this.pdf2 = tokgen.generate();
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: this.$t('Advertencia'), 
								detail: this.$t('Error en Foto'), 
								life: 3000
							});
						}
					});
				}
			},

        }
	}
</script>
